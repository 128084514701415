import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOut } from '@fortawesome/pro-light-svg-icons';
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from 'react-router-dom';
import { Grid, Header, Icon, Button } from 'semantic-ui-react';
import CustomModal from '../../components/CustomModal';
import ContactMsgModal from '../../components/ContactMsgModal';
import ContactResponse from '../../components/ContactResponse';
import './NavBar.css';
import logo from '../../images/autocase-A-logo.png';

const NavBar : React.FC = () => {
  const { getAccessTokenSilently, logout } = useAuth0();
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [contactFailed, setContactFailed] = useState(false);
  const [showContactRspModal, setShowContactRspModal] = useState(false);

  const handleModalClose = () : void => {
    setShowLogoutModal(false);
    setShowContactModal(false);
    setShowContactRspModal(false);
  };

  const openLogout = (): void => {
    setShowLogoutModal(true);
  };

  const openContact = (): void => {
    setShowContactModal(true);
  };

  const handleContactResponse = (status: boolean): void => {
    setShowContactModal(false);
    setContactFailed(status);
    setShowContactRspModal(true);
  };

  const notifyAccountManager = async (msg: string): Promise<void> => {
    try {
      const token = await getAccessTokenSilently();
      await axios.post(
        `${process.env.REACT_APP_FORK}/notify`,
        {
          message: `Contact Us Request - Autocase Dashboard: ${msg}`,
        },
        { headers: { Authorization: `Bearer ${token}` } },
      );
      handleContactResponse(false);
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401 || error.response.status === 403) {
          logout()
        }
      }
      handleContactResponse(true);
    }
  };

  return (
    <div className="nav-bar-container">
      <Grid>
        <Grid.Row>
          <Grid.Column widescreen={16} largeScreen={16} computer={16} tablet={16} mobile={2}>
            <div className="nav-bar-logo">
              <Link to="/">
                <img src={logo} alt="logo" className="nav-bar-img" />
              </Link>
            </div>
          </Grid.Column>
          <Grid.Column widescreen={16} largeScreen={16} computer={16} tablet={16} mobile={1}>
            <div className="nav-icon nav-icon-big">
              <a
                id="twitter-btn"
                className="nav-link"
                href="https://twitter.com/_autocase"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon link name="twitter square" size="big" />
              </a>
            </div>
          </Grid.Column>
          <Grid.Column widescreen={16} largeScreen={16} computer={16} tablet={16} mobile={1}>
            <div className="nav-icon nav-icon-big">
              <a
                id="linkedin-btn"
                className="nav-link"
                href="https://www.linkedin.com/company/impact-infrastructure-llc"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon link name="linkedin" size="big" />
              </a>
            </div>
          </Grid.Column>
          <Grid.Column widescreen={16} largeScreen={16} computer={16} tablet={16} mobile={1}>
            <Button
              onClick={openContact}
              className="nav-icon nav-icon-large icon-btn"
              id="contact-btn"
            >
              <Icon link name="mail outline" size="large" />
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Button className="nav-logout icon-btn" onClick={openLogout}>
        <FontAwesomeIcon icon={faSignOut} />
      </Button>
      <CustomModal
        id="logout-modal"
        modalOpen={showLogoutModal}
        handleModalClose={handleModalClose}
        handleCancel={handleModalClose}
        btnConfirmText="Ok"
        handleConfirm={() : void => logout()}
      >
        <Header> Logout </Header>
        <p> Are you sure you would like to logout? </p>
      </CustomModal>
      <CustomModal
        id="contact-resp-modal"
        modalOpen={showContactRspModal}
        handleModalClose={handleModalClose}
        handleCancel={handleModalClose}
        btnConfirmText="Ok"
        handleConfirm={handleModalClose}
        hideCancel
      >
        <ContactResponse contactFailed={contactFailed} />
      </CustomModal>
      <ContactMsgModal
        id="contact-modal"
        modalOpen={showContactModal}
        handleModalClose={handleModalClose}
        handleCancel={handleModalClose}
        handleConfirm={notifyAccountManager}
      />
    </div>
  );
};

export default NavBar;
