import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

const PrivateRoute : React.FC<{
    // eslint-disable-next-line react/require-default-props
    exact?: boolean;
    path: string;
}> = ({ children, ...rest }) => {
  const { isAuthenticated } = useAuth0();

  return (
    <Route
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      render={({ location }): React.ReactNode =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )}
    />
  );
}

export default PrivateRoute;