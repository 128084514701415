import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { List } from 'semantic-ui-react';
import './Addon.css';
import { mapIcon, addCommas } from '../../utils/styling-helper';

const Addon = ({ addon, type }) => {
  const icon = mapIcon(addon, type);
  return (
    <List.Item className='addon-list-item'>
      <div className='icon addon-icon'>
        <FontAwesomeIcon icon={icon.icon} style={{ color: icon.color }} />
      </div>
      <List.Content>
        <div className="addon-item">
          <List.Header>{icon.label}</List.Header>
          {addon.createdAt && (
            <span className="subtext">Purchased {new Date(addon.createdAt).toDateString()}</span>
          )}
          {addon.price && <span className="addon-price">${addCommas(addon.price)}</span>}
        </div>
      </List.Content>
    </List.Item>
  );
};

export default Addon;
