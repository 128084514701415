import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/pro-regular-svg-icons';
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";
import { Grid, Header, List, Button } from 'semantic-ui-react';
import Details from '../Details';
import Addon from '../Addon';
import CustomModal from '../CustomModal';
import ContactResponse from '../ContactResponse';
import ContactMsgModal from '../ContactMsgModal';
import './OrganizationSubs.css';

import { addCommas } from '../../utils/styling-helper';

const OrganizationSubs = ({ org, isAdmin }) => {
  const { getAccessTokenSilently, logout } = useAuth0();
  const { subscriptions, purchased_projects, users, manager } = org;
  const subscription = subscriptions[0];
  const [showContactModal, setShowContactModal] = useState(false);
  const [contactFailed, setContactFailed] = useState(false);
  const [showContactRspModal, setShowContactRspModal] = useState(false);
  const subInfo = [];
  if (isAdmin) {
    subInfo.push([
      { header: 'Plan Type', body: 'Autocase' },
      { header: 'Price', body: `${addCommas(subscription.price)}$` },
      { header: 'Currency', body: subscription.currency.toUpperCase() },
    ]);
  }
  const accountManagers = {
    shane: { name: 'Shane Minckley', email: 'shane.minckley@autocase.com' },
    riley: { name: 'Riley McKillop', email: 'riley.mckillop@autocase.com' },
    simon: { name: 'Simon Fowell', email: 'simon.fowell@autocase.com' },
    unassigned: { name: '', email: '' },
  };
  if (manager) {
    const orgManager = accountManagers[manager];
    if (orgManager && manager !== 'unassigned') {
      subInfo.push([
        { header: 'Account Manager', body: `${orgManager.name} (${orgManager.email})` },
      ]);
    } else {
      subInfo.push([{ header: 'Account Manager', body: 'Not Assigned' }]);
    }
  }
  subInfo.push([
    { header: 'Start Date', body: new Date(subscription.purchase_date).toDateString() },
  ]);
  subInfo.push([{ header: 'End Date', body: new Date(subscription.end_date).toDateString() }]);

  const emptyAddon = {
    id: 0,
    value: null,
    price: null,
    purchase_date: '',
  };

  const handleModalClose = () => {
    setShowContactModal(false);
    setShowContactRspModal(false);
  };

  const openContact = () => {
    setShowContactModal(true);
  };

  const handleContactResponse = (status) => {
    setShowContactModal(false);
    setContactFailed(status);
    setShowContactRspModal(true);
  };

  const notifyAccountManager = async (msg) => {
    try {
      const token = await getAccessTokenSilently();
      await axios.post(
        `${process.env.REACT_APP_FORK}/notify`,
        {
          message: `Contact Us Request - Autocase Dashboard: ${msg}`,
        },
        { headers: { Authorization: `Bearer ${token}` } },
      );
      handleContactResponse(false);
    } catch (error) {
      console.log(error);
      if (error.response) {
        if (error.response.status === 401 || error.response.status === 403) {
          logout();
        }
      }
      handleContactResponse(true);
    }
  };

  return (
    <div>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Header as="h3">
              Current Subscription
              {new Date() > new Date(subscription.end_date) && (
                <span className="expired">(Expired)</span>
              )}
            </Header>
            <Details details={subInfo} />
          </Grid.Column>
        </Grid.Row>
        {isAdmin && (
          <Grid.Row>
            <Grid.Column>
              <Header as="h3">Purchased Projects</Header>
              <List divided relaxed>
                {purchased_projects.length !== 0 &&
                  purchased_projects.map((project) => (
                    <Addon addon={project} key={project.id} type="project" />
                  ))}
                {purchased_projects.length === 0 && (
                  <Addon addon={emptyAddon} key={emptyAddon.id} type="no_projects" />
                )}
              </List>
            </Grid.Column>
          </Grid.Row>
        )}
        {false && (
          <Grid.Row>
            <Grid.Column>
              <Header
                as="h3"
                content="Purchased Support Hours"
                subheader="Hours are updated on a weekly basis."
              />
              <List divided relaxed>
                {subscription.purchased_hours.length !== 0 &&
                  subscription.purchased_hours.map((hours) => (
                    <Addon addon={hours} key={hours.id} type="hours" />
                  ))}
                {subscription.purchased_hours.length === 0 && (
                  <Addon addon={emptyAddon} key={emptyAddon.id} type="no_hours" />
                )}
              </List>
            </Grid.Column>
          </Grid.Row>
        )}
        {isAdmin && (
          <Grid.Row>
            <Grid.Column
              widescreen={13}
              largeScreen={13}
              computer={12}
              tablet={10}
              mobile={10}
              verticalAlign="middle"
            >
              <Header as="h4">
                Contact an account manager to edit this subscription or purchase add-ons.
              </Header>
            </Grid.Column>
            <Grid.Column widescreen={3} largeScreen={3} computer={4} tablet={6} mobile={6}>
              <Button
                className="org-contact-btn"
                onClick={openContact}
                icon
                labelPosition="left"
                floated="right"
                primary
              >
                <FontAwesomeIcon icon={faPhone} className='icon' />
                Contact
              </Button>
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
      <CustomModal
        className="contact-resp-modal"
        modalOpen={showContactRspModal}
        handleModalClose={handleModalClose}
        handleCancel={handleModalClose}
        btnConfirmText="Ok"
        handleConfirm={handleModalClose}
        hideCancel
      >
        <ContactResponse contactFailed={contactFailed} />
      </CustomModal>
      <ContactMsgModal
        className="org-contact-modal"
        modalOpen={showContactModal}
        handleModalClose={handleModalClose}
        handleCancel={handleModalClose}
        handleConfirm={notifyAccountManager}
      />
    </div>
  );
};

export default OrganizationSubs;
