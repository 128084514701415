import React, { useState } from 'react';
import { Button, Image, Modal, Form, TextArea } from 'semantic-ui-react';
import logo from '../../images/autocase-logo.png';
import './ContactMsgModal.css';

const ContactMsgModal = (props) => {
  const { modalOpen, handleModalClose, handleConfirm, handleCancel } = props;

  const [msg, setMsg] = useState('');

  const handleChange = (e, { value }) => setMsg(value);

  return (
    <Modal
      open={modalOpen}
      onClose={handleModalClose}
      as={Form}
      onSubmit={() => handleConfirm(msg)}
      id="contact-form"
    >
      <Modal.Content image>
        <div className="ui small image">
          <Image wrapped size="small" src={logo} />
        </div>
        <Modal.Description className="modal-description contact-msg-modal-description">
          <Form.Group widths="equal">
            <Form.Field
              id="contact-msg"
              control={TextArea}
              required
              label="How can we help you?"
              placeholder="I would like to be contacted by an Account Manager."
              onChange={handleChange}
            />
          </Form.Group>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={handleCancel} id="contact-cancel" color="red" inverted>
          Cancel
        </Button>
        <Button type="submit" color="green" inverted>
          Send
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ContactMsgModal;
