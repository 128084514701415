import React from 'react';

import './CustomLoader.css';

const CustomLoader = () => (
  <div className="custom-loader">
    <div className="lds-roller">
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
);

export default CustomLoader;
