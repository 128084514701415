import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { Button, Grid, Header, Input, Segment, Select } from 'semantic-ui-react';
import { useToasts } from 'react-toast-notifications';
import { useAuth0 } from '@auth0/auth0-react';
import MemberList from '../MemberList';
import OrganizationContent from '../OrganizationContent';
import CustomModal from '../CustomModal';
import './Members.css';

const Members = ({ org, setOrganization, isAdmin, quota, changetab }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [showModal, setShowModal] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [newEmail, setNewEmail] = useState('');
  const [validateNewEmail, setValidateNewEmail] = useState('');
  const [membershipCategory, setMembershipCategory] = useState('member')
  const [errorMsg, setErrorMsg] = useState({ message: '', inputName: '' });
  const [memberToRemove, setMemberToRemove] = useState(null);
  const [members, setMembers] = useState(org.users);
  const { addToast } = useToasts();
  const [openConfirm, setOpenConfirm] = useState(false);
  const [hasAccount, setHasAccount] = useState(false);
  const pendingInviteTotal = org.invitations.filter((inv) => !inv.is_accepted).length;
  const history = useHistory();
  const userReqBody = {
    email: newEmail,
    category: membershipCategory,
  };
  const membershipOptions = [
    { key: 'member', value: 'member', text: 'Member' },
    { key: 'admin', value: 'admin', text: 'Administrator' },
  ]
  // eslint-disable-next-line no-unused-expressions
  org?.orgSettings.find((setting) => setting.name === 'is_fb') && membershipOptions.push({
    key: 'owner', value: 'owner', text: 'Organization Owner'
  })

  useEffect(() => {
    changetab('members');
  }, []);

  const handleCloseModal = () => {
    setShowModal(false);
    setShowDelete(false);
    setMemberToRemove(null);
  }

  const openDeleteModal = (member) => {
    setMemberToRemove(member);
    setShowDelete(true);
  }

  const handleCloseConfirmModal = () => {
    setNewEmail('');
    setValidateNewEmail('');
    setOpenConfirm(false);
  }

  const validate = async () => {
    if (validateNewEmail !== newEmail) {
      setErrorMsg({
        message: 'Emails are not matching',
        inputName: 'email',
      });
      // Email regex found here: https://www.w3resource.com/javascript/form/email-validation.php
    } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(newEmail)) {
      setErrorMsg({
        message: 'Not a valid email address.',
        inputName: 'email',
      });
    } else {
      try {
        const token = await getAccessTokenSilently();
        const resp = await axios.get(`${process.env.REACT_APP_FORK}/exists/${newEmail}`, { headers: { Authorization: `Bearer ${token}` } });
        setHasAccount(!!resp.data.exist);
        setOpenConfirm(true);
      } catch {
        addToast('Failed to check member. Please check if the email address entered is correct.', {
          appearance: 'error',
        });
      } finally {
        handleCloseModal();
      }
    }
  }

  const addUser = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.put(
        `${process.env.REACT_APP_FORK}/organizations/${org.id}/memberships`,
        { email: newEmail, category: membershipCategory },
        { headers: { Authorization: `Bearer ${token}` } },
      );
      const newuser = response.data.user;
      newuser.memberships = response.data.membership;
      const newMembers = [...members, newuser];
      setMembers(newMembers);
      setOrganization({ ...org, users: newMembers });
      addToast(`${newEmail} has been added to the organization.`, { appearance: 'success' });
    } catch {
      addToast('Failed to add member. Please check if the email address entered is correct.', {
        appearance: 'error',
      });
    } finally {
      handleCloseConfirmModal();
    }
  }

  const inviteUser = async () => {
    try {
      const token = await getAccessTokenSilently();
      const respInvite = await axios.post(
        // need to stay in fork, sending out invitation with postmark
        `${process.env.REACT_APP_FORK}/organizations/${org.id}/invitations`,
        { email: newEmail, category: membershipCategory },
        { headers: { Authorization: `Bearer ${token}` } },
      );
      setOrganization({ ...org, invitations: [...org.invitations, respInvite.data] });
      addToast(`${newEmail} has been invited to the organization.`, { appearance: 'success' });

      // Redirect to Invitations tab after an invitation has been sent
      history.push(`/organizations/${org.id}/invitations`);
    } catch (error) {
      // handle error with status code 502
      addToast(
        /502$/.test(error.message)
          ? `${newEmail} has already been invited`
          : 'Failed to invite user.',
        { appearance: 'error' },
      );
    } finally {
      handleCloseConfirmModal();
    }
  }

  const deleteUser = async () => {
    try {
      const token = await getAccessTokenSilently();
      await axios.delete(
        // this call needs to stay in fork - removing collab, portfolio and transferring projects
        `${process.env.REACT_APP_FORK}/organizations/${org.id}/memberships/${memberToRemove.memberships.id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
          params: { user_id: memberToRemove.memberships.user_id },
        },
      );
      const remainderMembers = members.filter((user) => user.email !== memberToRemove.email);
      setMembers(remainderMembers);
      setOrganization({ ...org, users: remainderMembers });
      addToast(`Successfully removed ${memberToRemove.name} from your organization.`, {
        appearance: 'success',
      });
    } catch {
      addToast(`Failed to remove ${memberToRemove.name} from your organization.`, {
        appearance: 'error',
      });
    } finally {
      handleCloseModal();
    }
  }


  return (
    <OrganizationContent>
      <div id="members">
        <Grid columns={2}>
          <Grid.Column>
            <div className="members-title">
              <Header as="h2"> Members </Header>
            </div>
          </Grid.Column>
          <Grid.Column>
            <div className="add-member-container">
              {isAdmin && (
                <Button primary onClick={() => setShowModal(true)}>
                  Add Member
                </Button>
              )}
            </div>
          </Grid.Column>
        </Grid>
        <div className="member-list">
          <Segment>
            <MemberList
              members={members.filter((user) => user.memberships.category !== 'collab')}
              setMembers={setMembers}
              onDelete={openDeleteModal}
              isAdmin={isAdmin}
            />
          </Segment>
        </div>
        {members.filter((user) => user.memberships.category === 'collab').length !== 0 && (
          <div className="collabs">
            <Header as="h2">
              <span className="members-title">Collaborators</span>
            </Header>
            <div className="member-list">
              <MemberList
                members={members.filter((user) => user.memberships.category === 'collab')}
                onDelete={openDeleteModal}
                isAdmin={isAdmin}
              />
            </div>
          </div>
        )}

        <CustomModal
          modalOpen={showDelete}
          handleCancel={handleCloseModal}
          btnConfirmText="Yes"
          handleConfirm={deleteUser}
        >
          <div className="modal-msg">
            <Header> Remove a Member </Header>
            <p> Are you sure you want to remove this member from your organization?</p>
          </div>
        </CustomModal>
        <CustomModal
          modalOpen={showModal}
          handleCancel={handleCloseModal}
          btnConfirmText="Next"
          handleConfirm={validate}
        >
          <div className="modal-msg">
            <Header> Add a new member to your organization </Header>
            <p> Please enter the email of the newest member of your organization.</p>
            <Grid>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <div className="add-input-field">
                    <h5 className="add-member-label"> Email </h5>
                    <Input
                      className="add-member-input"
                      type="text"
                      placeholder="example@example.com"
                      value={newEmail}
                      onChange={(e) => setNewEmail(e.target.value)}
                    />
                    {errorMsg.inputName === 'email' && (
                      <p className="error-msg"> {errorMsg.message} </p>
                    )}
                  </div>
                </Grid.Column>
                <Grid.Column>
                  <div className="add-input-field">
                    <h5 className="add-member-label"> Confirm Email </h5>
                    <Input
                      className="add-member-input"
                      type="text"
                      placeholder="example@example.com"
                      value={validateNewEmail}
                      onChange={(e) => setValidateNewEmail(e.target.value)}
                    />
                  </div>
                </Grid.Column>
                <Grid.Column>
                  <div className="add-input-field">
                    <h5 className="add-member-label"> Membership Type </h5>
                    <Select
                      options={membershipOptions}
                      value={membershipCategory}
                      onChange={(e, data) => {
                        setMembershipCategory(data.value)
                      }}
                    />
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </CustomModal>
        <CustomModal
          modalOpen={openConfirm}
          handleCancel={() => setOpenConfirm(false)}
          btnConfirmText={hasAccount ? 'Add' : 'Invite'}
          handleConfirm={hasAccount ? addUser : inviteUser}
        >
          {hasAccount ? (
            <p>
              <strong>{newEmail}</strong> will be added to your organization.
            </p>
          ) : (
            <>
              <p>
                The person you invited does not have an Autocase account yet. An invitation to sign
                up will be sent to <strong>{newEmail}</strong>.
              </p>
              <p>
                Once they create an Autocase account, they will be able to join your organization.
              </p>
            </>
          )}
        </CustomModal>
      </div>
    </OrganizationContent>
  );
};

export default Members;
