import React from 'react';
import { faBookmark, faCalendarAlt } from '@fortawesome/pro-regular-svg-icons';
import { Header } from 'semantic-ui-react';
import Details from '../Details';
import 'react-circular-progressbar/dist/styles.css';
import './OrganizationStats.css';

const OrganizationStats = ({ org, quota, isAdmin }) => {
  const iconColor = 'rgb(70, 70, 70)';
  const orgInfo = [
    [
      {
        header: 'Name',
        body: org.name,
        icon: faBookmark,
        iconColor,
      },
      {
        header: 'Created on',
        body: new Date(org.createdAt).toDateString(),
        icon: faCalendarAlt,
        iconColor,
      },
    ],
  ];

  return (
    <>
      <Header as="h2">
        <Header.Content>
          {org.name}
        </Header.Content>
        {/* we'll add the following content back later: user count, total projects and project token 
        using sementic UI Statistic */}
      </Header>
      <div className="stats-info">
        <Details details={orgInfo} />
      </div>
    </>
  );
};

export default OrganizationStats;
