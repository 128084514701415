import React, { useEffect, useState } from 'react';
import axios from 'axios';
import _ from 'lodash';
import { useAuth0 } from "@auth0/auth0-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faKey } from '@fortawesome/pro-regular-svg-icons';
import { Button, Grid, Header, Image } from 'semantic-ui-react';
import OrganizationList from '../OrganizationList';
import CustomModal from '../CustomModal';
import CustomLoader from '../CustomLoader';
import Details from '../Details';
import './Homepage.css';
import buildingsLogo from '../../images/autocase-buildings-logo.png';
import VerifyBanner from '../VerifyBanner';

const Homepage = () => {
  const { getAccessTokenSilently, logout } = useAuth0();
  const [loading, setLoading] = useState(true);
  const [invitations, setInvitations] = useState([]);
  const [user, setUser] = useState({})

  const orgList = user.organizations ? user.organizations : [];

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const token = await getAccessTokenSilently();

        // Getting user info
        const userRsp = await axios.get(
          `${process.env.REACT_APP_FORK}/auth/user`,
          { headers: { Authorization: `Bearer ${token}` } },
        );
        const userData = userRsp.data;
        // get invitations
        const resp = await axios.get(
          `${process.env.REACT_APP_FORK}/auth/user/invitations`,
          { headers: { Authorization: `Bearer ${token}` } },
        );
        setInvitations(resp.data);
        setUser(userData)
        setLoading(false);
      } catch (error) {
        if (error.response) {
          if (error.response.status === 401 || error.response.status === 403) {
            logout();
          } else {
            console.error(Object.keys(error), error.message);
          }
        }
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const redirectToBuildings = () => {
    window.location.href = process.env.REACT_APP_BUILDINGS_HOST;
  };

  const redirectToCarbonsight = () => {
    window.location.href = process.env.REACT_APP_CARBONSIGHT_HOST;
  };

  const signupDate = new Date(user.created_at).toDateString();

  const userInfo = [
    [
      { header: 'Name', body: user.name, image: user.picture },
      { header: 'Email', body: user.email },
    ],
    [{ header: 'Sign Up Date', body: signupDate }],
  ];

  let startAppButton = (
    <Button
      content="TO AUTOCASE"
      onClick={redirectToBuildings}
      icon="right arrow"
      labelPosition="right"
      primary
    />
  );
  let csStartAppButton = (
    <Button
      content="TO CARBONSIGHT"
      onClick={redirectToCarbonsight}
      icon="right arrow"
      labelPosition="right"
      primary
      style={{ backgroundColor: "#4E2BA7" }}
    />
  );
  if (user.email_verified === false) {
    startAppButton = (
      <div data-tooltip="Please verify your email to continue." data-position="bottom center">
        <Button
          disabled
          content="TO AUTOCASE"
          onClick={redirectToBuildings}
          icon="right arrow"
          labelPosition="right"
          primary
        />
      </div>
    );
    csStartAppButton = (
      <div data-tooltip="Please verify your email to continue." data-position="bottom center">
        <Button
          disabled
          content="TO CARBONSIGHT"
          onClick={redirectToCarbonsight}
          icon="right arrow"
          labelPosition="right"
          primary
          color="#4E2BA7"
        />
      </div>
    );
  }

  if (loading) {
    return <CustomLoader />;
  }

  return (
    <div className="homepage">
      {!_.isEmpty(user) && !user.email_verified && <VerifyBanner />}
      <div className="homepage-content">
        <Grid centered>
          <Grid.Row>
            <Grid.Column widescreen={3} largeScreen={4} computer={7} tablet={6} mobile={15}>
              <Image className="centered autocase-logo" src={buildingsLogo} />
            </Grid.Column>
            <Grid.Column
              widescreen={5}
              largeScreen={5}
              computer={4}
              tablet={6}
              mobile={15}
              textAlign="center"
            >
              <p className="description">
                Autocase empowers you to determine the Financial, Social, and Environmental value of
                your projects.
              </p>
              <Grid centered style={{ width: 480, marginLeft: 'calc(50% - 230px)' }}>
                <Grid.Row>
                  <Grid.Column width={8}>
                    {startAppButton}
                  </Grid.Column>
                  <Grid.Column width={8}>
                    {csStartAppButton}
                  </Grid.Column>
                </Grid.Row>   
              </Grid>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column widescreen={8} largeScreen={9} computer={11} tablet={12} mobile={15}>
              <Header as="h1"> My Account </Header>
              <div className="my-account">
                <Details details={userInfo} options={['privacy']} />
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column widescreen={8} largeScreen={9} computer={11} tablet={12} mobile={15}>
              <Header as="h1">Organizations</Header>
              <OrganizationList organizations={orgList} invitations={invitations} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </div>
  );
};

export default Homepage;
