import React from 'react';
import { List, Button } from 'semantic-ui-react';
import '../OrganizationListItem/OrganizationListItem.css';

import axios from 'axios';
import { useToasts } from 'react-toast-notifications';
import { useAuth0 } from '@auth0/auth0-react';

const OrganizationInviteItem = ({ invitation, onDelete, onAccept }) => {
  const { addToast } = useToasts();
  const { getAccessTokenSilently } = useAuth0();

  const deleteInvite = async () => {
    try {
      const token = await getAccessTokenSilently();
      await axios.delete(`${process.env.REACT_APP_FORK}/auth/user/invitations/${invitation.id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      addToast('Rejected organization invite', { appearance: 'success' });
      onDelete(invitation.id);
    } catch (error) {
      console.error(error);
      addToast('Failed to respond to organization invitation. Try again later.', {
        appearance: 'error',
      });
    }
  };
  const acceptInvite = async () => {
    try {
      const token = await getAccessTokenSilently();
      const membership = await axios.put(
        `${process.env.REACT_APP_FORK}/auth/user/invitations/${invitation.id}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } },
      );
      addToast('Successfully joined organization', { appearance: 'success' });
      onDelete(invitation.id);

      const orgRsp = await axios.get(
        `${process.env.REACT_APP_FORK}/auth/organizations/${membership.data.org_id}`,
        { headers: { Authorization: `Bearer ${token}` } },
      );
      // The membership information is required to add when the user joined
      orgRsp.data.memberships = membership.data;
      onAccept(orgRsp.data);
    } catch (error) {
      console.error(error);
      addToast('Failed to respond to organization invitation. Try again later.', {
        appearance: 'error',
      });
    }
  };

  return (
    <List.Item>
      <List.Icon name="plus" style={{ color: 'green' }} verticalAlign="middle" />
      <List.Content>
        <div className="org-list-item">
          <List.Header>
            You have been invited to join the {invitation.name} organization
            <Button
              content="Decline"
              onClick={deleteInvite}
              icon="times"
              labelPosition="right"
              color="red"
              style={{ float: 'right' }}
              id="decline"
            />
            <Button
              content="Accept"
              onClick={acceptInvite}
              icon="check"
              labelPosition="right"
              color="green"
              style={{ float: 'right' }}
              id="accept"
            />
          </List.Header>
          <div className="subtext">Invited on {new Date(invitation.createdAt).toDateString()}</div>
        </div>
      </List.Content>
    </List.Item>
  );
};

export default OrganizationInviteItem;
