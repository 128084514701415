import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Route, Router, Switch } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import PrivateRoute from '../../components/PrivateRoute';
import ErrorBoundary from '../../components/ErrorBoundary';
import Callback from '../../components/Callback';
import Login from '../../components/Login';
import Homepage from '../../components/Homepage';
import Organization from '../Organization';
import Footer from '../../components/Footer';
import NavBar from '../NavBar';
import Register from '../../components/Register';
import history from '../../utils/history';
import CustomToast from '../../components/CustomToast';
import CustomLoader from '../../components/CustomLoader';

import './App.css';

const App : React.FC = () => {
  const {
    isLoading,
    error
  } = useAuth0();

  if (isLoading) {
    return <CustomLoader />;
  }
  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  return (
    <ToastProvider components={{ Toast: CustomToast }} autoDismiss>
      <Router history={history}>
        <div className="App">
          <ErrorBoundary>
            <div id="dashboard-app">
              <NavBar />
              <div className="dashboard-content">
                <Switch>
                  <PrivateRoute exact path="/">
                    <Homepage /> 
                  </PrivateRoute>
                  <PrivateRoute path="/organizations/:orgId">
                    <Organization />
                  </PrivateRoute>
                  <Route exact path="/login"> 
                    <Login />
                  </Route>
                  <Route exact path="/register"> 
                    <Register />
                  </Route>
                  <Route exact path="/callback"> 
                    <Callback />
                  </Route>
                </Switch>
              </div>
              <Footer />
            </div>
          </ErrorBoundary>
        </div>
      </Router>
    </ToastProvider>
  );
};

export default App;
