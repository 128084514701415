import React from 'react';
import { Grid } from 'semantic-ui-react';

const OrganizationContent = ({ children }) => (
  <div>
    <Grid centered>
      <Grid.Row>
        <Grid.Column>{children}</Grid.Column>
      </Grid.Row>
    </Grid>
  </div>
);

export default OrganizationContent;
