import React from 'react';
import { Item, Button, Label, Input } from 'semantic-ui-react';

import './MemberList.css';

const MemberList = ({ members, setMembers, onDelete, isAdmin }) => {
  return (
    <Item.Group divided>
      {members.map((member, index) => (
        <Item key={member.memberships.id}>
          <Item.Image size="small" src={member.picture} />
          <Item.Content>
            <Item.Header>
              <span className="text-pad-right">{member.name}</span>
              {member.memberships.category === 'admin' && <Label color="blue">Admin</Label>}
              {member.memberships.category === 'owner' && <Label color="blue">Organization Owner</Label>}
            </Item.Header>
            <Item.Meta>
              {' '}
              <span>
                {member.email}
                <br />
              </span>{' '}
            </Item.Meta>
            <Item.Description>
              {' '}
              <span>Last Login Date: {new Date(member.last_login).toDateString()}</span> <br />
              <span>Member Since: {new Date(member.created_at).toDateString()}</span> <br />
              <span>Email Verified: {member.email_verified ? 'Yes' : 'No'}</span> <br />
            </Item.Description>
          </Item.Content>
          {isAdmin && !member.beingEdited && (
            <Item.Content>
              {member.memberships.category === 'member' && (
                <Button floated="right" onClick={() => onDelete(member)}>
                  Remove
                </Button>
              )}
            </Item.Content>
          )}
        </Item>
      ))}
    </Item.Group>
  );
};

export default MemberList;
