import React from 'react';
import { Header } from 'semantic-ui-react';

const ContactResponse = ({ contactFailed }) => (
  <div>
    {!contactFailed && (
      <div>
        <Header> Request Received </Header>
        <p> One of our Account Managers will reach out to you shortly. </p>
      </div>
    )}
    {contactFailed && (
      <div>
        <Header> Unable to Send Request </Header>
        <p>
          Sorry, we couldn&apos;t send your message right now, please use the Contact Us page{' '}
          <a href="https://autocase.com/contact/" target="_blank" rel="noopener noreferrer">
            {' '}
            here
          </a>
          .
        </p>
      </div>
    )}
  </div>
);
export default ContactResponse;
