/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Button, Image, Modal } from 'semantic-ui-react';
import logo from '../../images/autocase-logo.png';

const CustomModal = (props) => {
  const {
    modalOpen,
    handleModalClose,
    btnConfirmText,
    btnCancelText,
    handleConfirm,
    handleCancel,
    hideCancel,
    useDefault,
    children,
  } = props;

  const btnStyleProps = useDefault ? {
    confirm: { primary: true },
    cancel: { basic: true }
  } : {
    confirm: { color: 'green', inverted: true },
    cancel: { color: 'red', inverted: true }
  };

  return (
    <Modal open={modalOpen} onClose={handleModalClose}>
      <Modal.Content image>
        <div className="ui small image">
          <Image wrapped size="small" src={logo} />
        </div>
        <Modal.Description className="modal-description">{children}</Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        {!hideCancel && <Button onClick={handleCancel} {...btnStyleProps.cancel} content={btnCancelText || 'Cancel'} />}
        <Button onClick={handleConfirm} {...btnStyleProps.confirm} content={btnConfirmText || 'Ok'} />
      </Modal.Actions>
    </Modal>
  );
};

export default CustomModal;
