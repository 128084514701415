import React, { Component } from 'react';
import './ErrorBoundary.css';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  componentDidCatch(error, info) {
    console.log(error, info);
    this.setState({ hasError: true });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return (
        <div className="error">
          <h1>Something went wrong.</h1>
          <h2>Please contact an account manager at am@autocase.com.</h2>
        </div>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
