const pathColors = {
  buildings: '#2a75bb',
  sites: '#3e9b49',
  accessLevel: '#25b6ce',
  daysLeft: '#1d8d7e',
};

const planNames = {
  quick_start: 'Quick Start',
  premium: 'Premium',
  standard: 'Standard',

  leed_quick_start: 'Quick Start',
  leed_premium: 'Premium',
  leed_standard: 'Standard',

  gi_quick_start: 'Quick Start',
  gi_premium: 'Premium',
  gi_standard: 'Standard',

  internal: 'Internal',
  guest: 'Guest',
  educational: 'Educational',
  trial: 'Trial',
};

const progressBarHtml = {
  quick_start: ['QUICK', 'START'],
  premium: ['PREMIUM', 'PLAN'],
  standard: ['STANDARD', 'PLAN'],

  leed_quick_start: ['QUICK', 'START'],
  leed_premium: ['PREMIUM', 'PLAN'],
  leed_standard: ['STANDARD', 'PLAN'],

  gi_quick_start: ['QUICK', 'START'],
  gi_premium: ['PREMIUM', 'PLAN'],
  gi_standard: ['STANDARD', 'PLAN'],

  internal: ['INTERNAL', 'ACCESS'],
  guest: ['GUEST', 'ACCESS'],
  educational: ['STUDENT', 'PLAN'],
  trial: ['FREE', 'TRIAL'],
};

const membershipLabels = {
  admin: 'Administrator',
  owner: 'Organization Owner',
  collab: 'Collaborator',
  member: 'Member'
}

const setProjectLimit = (quota, platform) => {
  if (quota === 1000000 || quota === 'unlimited') {
    return platform === 'buildings' ? ['Unlimited', 'Design', 'Scenarios'] : ['Unlimited', 'Sites'];
  }
  if (quota === 1) {
    return platform === 'buildings' ? ['1', 'Design', 'Scenario'] : ['1', 'Site'];
  }
  return platform === 'buildings' ? [`${quota}`, 'Design', 'Scenarios'] : [`${quota}`, 'Sites'];
};

const setDaysLeft = (start, end) => {
  const msPerDay = 1000 * 60 * 60 * 24;
  const startDate = new Date(start);
  const endDate = new Date(end);
  const daysLeft = Math.floor((endDate - startDate) / msPerDay);
  return daysLeft > 0 ? daysLeft : 0;
};

const getPlatforms = (plan) => {
  if (plan.startsWith('gi')) {
    return ['sites'];
  }
  if (plan.startsWith('leed')) {
    return ['buildings'];
  }
  return ['buildings', 'sites'];
};

const getQuotaForAppMetadata = (plan) => {
  switch (plan) {
    case 'internal':
    case 'educational':
    case 'guest':
      return {
        buildings: 'unlimited',
        sites: 'unlimited',
      };
    case 'trial':
      return {
        buildings: 1,
        sites: 1,
      };
    case 'free':
      return {
        buildings: 0,
        sites: 0,
      };
    case 'premium':
      return {
        buildings: 'unlimited',
        sites: 50,
      };
    case 'standard':
      return {
        buildings: 20,
        sites: 5,
      };
    case 'quick_start':
      return {
        buildings: 2,
        sites: 1,
      };
    case 'leed_premium':
      return {
        buildings: 'unlimited',
      };
    case 'gi_premium':
      return {
        sites: 50,
      };
    case 'leed_standard':
      return {
        buildings: 20,
      };
    case 'gi_standard':
      return {
        sites: 5,
      };
    case 'leed_quick_start':
      return {
        buildings: 2,
      };
    case 'gi_quick_start':
      return {
        sites: 1,
      };
    default:
      return {};
  }
};

const getQuota = (buildProjectMax, planId) => {
  if (buildProjectMax) {
    if (planId.startsWith('leed')) {
      return {
        buildings: buildProjectMax,
      };
    }
    if (planId.startsWith('gi')) {
      return {
        sites: buildProjectMax,
      };
    }
    return {
      buildings: buildProjectMax,
      sites: buildProjectMax,
    };
  }
  return getQuotaForAppMetadata(planId);
};

const getRecentSubscriptions = (subscriptions) => {
  if (subscriptions.length < 2) {
    return subscriptions;
  }

  let recentSubscription;
  let recentActivation;

  subscriptions.forEach((subscription) => {
    if (!recentActivation) {
      recentActivation = subscription.activated_at;
    }

    if (subscription.activated_at >= recentActivation) {
      recentActivation = subscription.activated_at;
      recentSubscription = subscription;
    }
  });

  return [recentSubscription];
};

const filterSubscriptions = (subscriptions, platform) =>
  Object.values(subscriptions).filter((subscription) => subscription.platforms[0] === platform);

const getValidSubscriptions = (subscriptions) => {
  let buildingsSubs = filterSubscriptions(subscriptions, 'leed');
  let sitesSubs = filterSubscriptions(subscriptions, 'gi');

  buildingsSubs = getRecentSubscriptions(buildingsSubs);
  sitesSubs = getRecentSubscriptions(sitesSubs);

  return [...buildingsSubs, ...sitesSubs];
};

export {
  pathColors,
  planNames,
  membershipLabels,
  progressBarHtml,
  getPlatforms,
  getQuota,
  getQuotaForAppMetadata,
  filterSubscriptions,
  getRecentSubscriptions,
  getValidSubscriptions,
  setProjectLimit,
  setDaysLeft
};
