import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDoubleLeft } from '@fortawesome/pro-regular-svg-icons';
import axios from 'axios';
import _ from 'lodash';
import { useAuth0 } from "@auth0/auth0-react";
import { BrowserRouter as Router, Link, Route, Switch, useParams, useHistory} from 'react-router-dom';
import { Grid, Menu, Image, Button } from 'semantic-ui-react';
import CustomLoader from '../../components/CustomLoader';
import Invitations from '../../components/Invitations';
import Members from '../../components/Members';
import OrganizationInfo from '../../components/OrganizationInfo';
import './Organization.css';
import squares from '../../images/squares.png';
import square from '../../images/square.png';
import VerifyBanner from '../../components/VerifyBanner';

const Organization = (props) => {
  const history = useHistory();
  const { getAccessTokenSilently, logout } = useAuth0();
  const { orgId } = useParams();
  const [isAdmin, setIsAdmin] = useState(false);
  const [quota, setQuota] = useState({
    used_hours: 0,
    license_projects: 0,
    license_hours: 0,
    addon_projects: 0,
    addon_hours: 0,
    total_projects: 0,
    total_hours: 0,
    used_projects: 0,
  });
  const [user, setUser] = useState({})
  const [activeTab, setActiveTab] = useState('');
  const [loading, setLoading] = useState(true);
  const [organization, setOrganization] = useState({
    name: '',
    users: [],
    invitations: [],
    createdAt: '',
    licenses: [{ subscriptions: {} }],
    addons: [],
    orgSettings: [],
  });
  const isExternalDemoOrg = organization.orgSettings.find(setting => setting.name === 'is_external_demo_org')

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = await getAccessTokenSilently();

        // Getting user info
        const userRsp = await axios.get(
          `${process.env.REACT_APP_FORK}/auth/user`,
          { headers: { Authorization: `Bearer ${token}` } },
        );
        const userData = userRsp.data;
        setUser(userData)

        // Getting Organization info
        const orgRsp = await axios.get(`${process.env.REACT_APP_FORK}/organizations/${orgId}`, {
          headers: { Authorization: `Bearer ${token}` }
        },);
        orgRsp.data.users.forEach((orgUser) => {
          if (orgUser.email === userData.email)
            setIsAdmin(orgUser.memberships.category === 'admin' || orgUser.memberships.category === 'owner');
        });
        setOrganization(orgRsp.data);

        // Getting quota info
        const quotaRsp = await axios.get(
          `${process.env.REACT_APP_FORK}/organizations/${orgId}/quota`,
          { headers: { Authorization: `Bearer ${token}` } },
        );
        setQuota(quotaRsp.data);
        setLoading(false);
      } catch (error) {
        if (error.response) {
          if (error.response.status === 401 || error.response.status === 403) {
            logout();
          } else {
            console.error(Object.keys(error), error.message);
          }
        }
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const changeTab = (newTab) => {
    setActiveTab(newTab);
  };

  const toDashboard = () => {
    history.goBack()
  };

  if (loading) {
    return <CustomLoader />;
  }
  return (
    <div>
      {!_.isEmpty(user) && !user.email_verified && <VerifyBanner />}
      <Router>
        <div className="organization">
          <Grid centered>
            <Grid.Row>
              <Grid.Column widescreen={12} largeScreen={12} computer={15} tablet={15} mobile={15}>
                <Button onClick={toDashboard} icon className="back">
                  <FontAwesomeIcon icon={faChevronDoubleLeft} />
                  Back
                </Button>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column
                widescreen={3}
                largeScreen={3}
                computer={4}
                tablet={5}
                mobile={15}
                className="org-tabs"
              >
                <div className="squares org-squares">
                  {' '}
                  <Image src={squares} />{' '}
                </div>
                <div className="squares org-square">
                  {' '}
                  <Image src={square} />{' '}
                </div>
                <Menu fluid vertical tabular>
                  <Menu.Item
                    as={Link}
                    to={`/organizations/${orgId}/info`}
                    name="Organization Information"
                    active={activeTab === 'info'}
                  />
                  {!isExternalDemoOrg &&
                    <Menu.Item
                      as={Link}
                      to={`/organizations/${orgId}/members`}
                      name="Members"
                      active={activeTab === 'members'}
                    />
                  }
                  {isAdmin && (
                    <Menu.Item
                      as={Link}
                      to={`/organizations/${orgId}/invitations`}
                      name="Invitations"
                      active={activeTab === 'invitations'}
                    />
                  )}
                </Menu>
              </Grid.Column>
              <Grid.Column widescreen={9} largeScreen={9} computer={11} tablet={10} mobile={15}>
                <Switch>
                  <Route path="/organizations/:orgId/info">
                    <OrganizationInfo
                      org={organization}
                      setOrganization={setOrganization}
                      changetab={changeTab}
                      isAdmin={isAdmin}
                      quota={quota}
                    />
                  </Route>
                  {!isExternalDemoOrg &&
                    <Route path="/organizations/:orgId/members">
                      <Members
                        org={organization}
                        setOrganization={setOrganization}
                        changetab={changeTab}
                        isAdmin={isAdmin}
                        quota={quota}
                      />
                    </Route>
                  }
                  {isAdmin && (
                    <Route path="/organizations/:orgId/invitations">
                      <Invitations
                        org={organization}
                        setOrganization={setOrganization}
                        changetab={changeTab}
                        isAdmin={isAdmin}
                        quota={quota}
                      />
                    </Route>
                  )}
                </Switch>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </Router>
    </div>
  );
};

export default Organization;
