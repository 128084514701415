import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Segment, Item } from 'semantic-ui-react';
import './Details.css';

const Details = ({ details }) => {
  const gridDetails = [];
  details.forEach((detailRow, i) => {
    const rowDetails = [];
    detailRow.forEach((field) => {
      const segment = (
        <Segment key={field.header}>
          <Item className="details-item-container">
            {field.image && (
              <Item.Image className="details-item-image" size="mini" src={field.image} />
            )}
            {field.icon && (
              <div className="details-item-icon">
                <FontAwesomeIcon icon={field.icon} style={{ color: field.iconColor }} />
              </div>
            )}
            <Item.Content>
              <Item.Header className="details-item-header">{field.header}</Item.Header>
              <Item.Meta className="details-item-description">
                {field.body !== 'Invalid Date' && field.body}
              </Item.Meta>
            </Item.Content>
          </Item>
        </Segment>
      );
      rowDetails.push(segment);
    });
    const key = `row_${i}`;
    const rowSegment = (
      <Segment.Group horizontal key={key}>
        {rowDetails}
      </Segment.Group>
    );
    gridDetails.push(rowSegment);
  });

  return (
    <div className="details-container">
      <Segment.Group>{gridDetails}</Segment.Group>
    </div>
  );
};

export default Details;
