import React from 'react';

const Register = () => {
  const redirectToSignup = () => {
    const redirectUri = `${window.location.protocol}//${window.location.hostname}/callback`
    window.location.href = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/login?state=g6Fo2SB2VEhBcUl4MEpZVUJueF9TeTZ1LWxidmJIdlBWZzdvOKN0aWTZIDd2SzJ5aTA3dzdzX0p0dzNCZ0FjSFVsR2syQ0duLS1Zo2NpZNkga3ZVZ2I5V0FwWDNiT0NkY0tEdkpXT0pGbE1ndkpuS0Y&client=1WAZwqWfaLnF3PH0Uh92mzbWhrbxNA62&protocol=oauth2&response_type=token%20id_token&redirect_uri=${redirectUri}&scope=openid%20email&nonce=BLi8p2Pmk3VzRb7wsbfp865hXI6rLIY6&auth0Client=eyJuYW1lIjoiYW5ndWxhci1hdXRoMCIsInZlcnNpb24iOiIzLjAuNCIsImVudiI6eyJhdXRoMC1qcyI6IjkuMTEuMyIsImF1dGgwLmpzIjoiOS4xMS4zIn19&mode=signup`
  }
  redirectToSignup()
  return <div> </div>;
};
export default Register;
