import { faPhoneSquare, faChartBar, faInfoCircle, faPlusSquare } from '@fortawesome/pro-regular-svg-icons';

const colorList = [
  '#696969',
  '#92acd5',
  '#F08080',
  '#019688',
  '#4682B4',
  '#2185d0',
  '#5f7c8a',
  '#000080',
];

const randomColor = (string) => {
  let sum = 0;
  for (let i = 0; i < string.length; i += 1) {
    sum += string.charCodeAt(i);
  }
  if (!sum) {
    return 'black';
  }

  return colorList[sum % colorList.length];
};

const mapIcon = (addon, type) => {
  switch (type) {
    case 'hours':
      return { icon: faPhoneSquare, color: '#45ba7d', label: `${addon.value} Support Hours` };
    case 'project':
      return { icon: faChartBar, color: '#2185d0', label: `${addon.value} Projects` };
    case 'no_projects':
      return { icon: faInfoCircle, color: 'grey', label: 'No projects have been purchased' };
    case 'no_hours':
      return { icon: faInfoCircle, color: 'grey', label: 'No hours have been purchased' };
    default:
      return { icon: faPlusSquare, color: 'grey', label: 'Other' };
  }
};

const addCommas = (number) => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export { randomColor, mapIcon, addCommas };
