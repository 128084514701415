import React, { useState } from 'react';
import { Grid, Header, List, Segment } from 'semantic-ui-react';
import OrganizationListItem from './OrganizationListItem';
import OrganizationInviteItem from './OrganizationInviteItem';
import './OrganizationList.css';

const OrganizationList = ({ invitations, organizations }) => {
  const [invitationState, setInvitations] = useState(invitations);
  const [organizationsState, setOrganizations] = useState(organizations);

  const handleDelete = (itemId) => {
    setInvitations(invitations.filter((item) => item.id !== itemId));
  };

  const handleAccept = (newOrganization) => {
    setOrganizations([...organizationsState, newOrganization]);
  };

  return (
    <div>
      <Grid centered>
        <Grid.Row>
          <Grid.Column>
            <Segment>
              <List divided relaxed>
                {invitationState.map((invitation) => (
                  <OrganizationInviteItem
                    invitation={invitation}
                    onDelete={handleDelete}
                    onAccept={handleAccept}
                    key={invitation.id}
                  />
                ))}
                {organizationsState.length !== 0 &&
                  organizationsState.map((org) => <OrganizationListItem org={org} key={org.id} />)}
                {organizationsState.length === 0 && (
                  <div className="no-org">
                    <List.Item>
                      <List.Content>
                        <Header as="h4">You do not belong to any organizations</Header>
                      </List.Content>
                    </List.Item>
                  </div>
                )}
              </List>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default OrganizationList;
