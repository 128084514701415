import React from 'react';
import { Grid } from 'semantic-ui-react';
import './Footer.css';

const Footer = () => (
  <div className="footer">
    <Grid centered>
      <Grid.Row>
        <Grid.Column
          floated="left"
          className="policy"
          widescreen={3}
          largeScreen={3}
          computer={3}
          tablet={5}
          mobile={5}
        >
          <div className="text-left">
            <a
              href="https://autocase.com/wp-content/uploads/2020/10/terms.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="light-sky-blue"
            >
              Terms
            </a>{' '}
            |
            <a
              href="https://s3-us-west-2.amazonaws.com/autocase-sb/privacy.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="light-sky-blue"
            >
              {' '}
              Privacy
            </a>
          </div>
        </Grid.Column>
        <Grid.Column
          floated="right"
          widescreen={13}
          largeScreen={13}
          computer={13}
          tablet={11}
          mobile={11}
        >
          <div className="text-right">
            © {new Date().getFullYear()}{' '}
            <a
              href="https://autocase.com"
              target="_blank"
              rel="noopener noreferrer"
              className="light-sky-blue"
            >
              IMPACT INFRASTRUCTURE
            </a>{' '}
            <br />
            All rights reserved.
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </div>
);

export default Footer;
