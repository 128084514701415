import React from 'react';
import { Message } from 'semantic-ui-react';
import axios from 'axios';
import { useToasts } from 'react-toast-notifications';
import { useAuth0 } from "@auth0/auth0-react";
import './VerifyBanner.css';

const VerifyBanner = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const { addToast } = useToasts();

  const resendEmail = async () => {
    try {
      const token = await getAccessTokenSilently();
      await axios.post(`${process.env.REACT_APP_FORK}/verify-email`, {}, { headers: { Authorization: `Bearer ${token}` } },);
      addToast('Email verification is sent.', { appearance: 'success' });
    } catch (error) {
      addToast('Failed to send email. Try again later.', {
        appearance: 'error',
      });
    }
  };

  return (
    <Message warning className="verify-banner">
      <div className="verify-text">
        Please verify your email to continue. If you&apos;d like to resend the verification email
        please click{' '}
        <button className="verify-send-btn" type="button" onClick={resendEmail}>
          {' '}
          here{' '}
        </button>
        .
      </div>
    </Message>
  );
};

export default VerifyBanner;
