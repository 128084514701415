import React from 'react';
import { Link } from 'react-router-dom';
import { List } from 'semantic-ui-react';
import './OrganizationListItem.css';
import { randomColor } from '../../../utils/styling-helper';
import { membershipLabels } from '../../../utils/dashboard-helper';

const OrganizationListItem = ({ org }) => {
  const iconColor = randomColor(org.name);

  return (
    <>
      {org.memberships.category !== 'collab' && (
        <List.Item as={Link} to={`/organizations/${org.id}/info`}>
          <List.Icon name="circle" style={{ color: iconColor }} verticalAlign="middle" />
          <List.Content>
            <div className="org-list-item">
              <List.Header>{org.name}</List.Header>
              <div className="subtext">
                {membershipLabels[org.memberships.category]}
              </div>
              <div className="subtext">
                Joined {new Date(org.memberships.createdAt).toDateString()}
              </div>
            </div>
          </List.Content>
        </List.Item>
      )}
      {org.memberships.category === 'collab' && (
        <List.Item>
          <List.Icon name="circle" style={{ color: iconColor }} verticalAlign="middle" />
          <List.Content>
            <div className="org-list-item">
              <List.Header>
                <span className="collab-item">{org.name} (Collaborating)</span>
              </List.Header>
              <div className="subtext">
                Collaborated {new Date(org.memberships.createdAt).toDateString()}
              </div>
            </div>
          </List.Content>
        </List.Item>
      )}
    </>
  );
};

export default OrganizationListItem;
