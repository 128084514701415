import React, { useState, useEffect } from 'react';
import { useToasts } from 'react-toast-notifications';
import { Button, Header } from 'semantic-ui-react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import CustomModal from '../CustomModal';
import OrganizationStats from '../OrganizationStats';
import OrganizationSubs from '../OrganizationSubs';
import OrganizationContent from '../OrganizationContent';
import './OrganizationInfo.css';

const OrganizationInfo = ({ changetab, isAdmin, quota, org }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [modalOpen, setModalOpen] = useState(false);
  const { addToast } = useToasts();
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  useEffect(() => {
    changetab('info');
  }, []);

  // Puts the current subscription at the front of the list
  org.subscriptions.sort((a, b) => a.is_current - b.is_current).reverse();

  const leaveOrganization = async () => {
    try {
      const token = await getAccessTokenSilently();
      // this call needs to stay with fork because of collab, project amd portfolio updates in db
      await axios.delete(`${process.env.REACT_APP_FORK}/user/organizations/${org.id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setModalOpen(false);
      // Need to use window.location.pathname to reload the page to update user's data
      window.location.pathname = '/';
    } catch (error) {
      console.error(error);
      setModalOpen(false);
      addToast('Failed to leave from organization. Please, try again later.', {
        appearance: 'error',
      });
    }
  };

  return (
    <OrganizationContent>
      {!isAdmin && (
        <Button className="leave-btn" inverted color="red" onClick={openModal}>
          Leave
        </Button>
      )}
      <div className="org-stats">
        <OrganizationStats org={org} quota={quota} isAdmin={isAdmin} />
      </div>
      {!!org.subscriptions.length && org.subscriptions[0] != null && (
        <OrganizationSubs org={org} isAdmin={isAdmin} />
      )}
      <CustomModal
        modalOpen={modalOpen}
        handleModalClose={closeModal}
        handleCancel={closeModal}
        btnConfirmText="Leave"
        handleConfirm={leaveOrganization}
      >
        <Header> Leave Organization </Header>
        <p>
          Are you sure you want to leave this organization? This change cannot be undone. If you
          have any leftover projects, they will be transferred to your organization admin.
        </p>
      </CustomModal>
    </OrganizationContent>
  );
};

export default OrganizationInfo;
