import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/pro-regular-svg-icons';
import { Button, Header, Item } from 'semantic-ui-react';
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";
import { useToasts } from 'react-toast-notifications';

import { membershipLabels } from '../../utils/dashboard-helper'
import OrganizationContent from '../OrganizationContent';
import './Invitations.css';

const Invitations = ({ org, setOrganization, changetab }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [invitations, setInvitations] = useState(org.invitations);
  const { addToast } = useToasts();
  const pendingInvitations = invitations.filter((invite) => !invite.is_accepted);

  useEffect(() => {
    changetab('invitations');
  }, []);

  async function removeInvitation(invite) {
    try {
      const token = await getAccessTokenSilently();
      await axios.delete(
        `${process.env.REACT_APP_FORK}/auth/organizations/${org.id}/invitations/${invite.id}`,
        { headers: { Authorization: `Bearer ${token}` } },
      );

      const updatedInvitations = invitations.filter(
        (invitation) => invitation.email !== invite.email,
      );
      setInvitations(updatedInvitations);
      setOrganization((prevOrg) => ({ ...prevOrg, invitations: updatedInvitations }));
      addToast(`Successfully removed ${invite.email} from your organization.`, {
        appearance: 'success',
      });
    } catch {
      addToast(`Failed to remove ${invite.email} from your organization.`, { appearance: 'error' });
    }
  }

  return (
    <OrganizationContent>
      <div id="invitations">
        <Header as="h2">
          <span className="members-title">Invitations</span>
        </Header>
        <Header sub className="invitations-sub-header">
          * These are pending invitations to non-Autocase members.
        </Header>
        <Item.Group divided>
          {pendingInvitations.length ? (
            pendingInvitations.map((invitation) => (
              <Item key={invitation.id} className="invitation">
                <div className="invitation-icon">
                  <FontAwesomeIcon icon={faClock} />
                </div>
                <Item.Content className="invitation-content">
                  <Item.Header className="invitation-header">{invitation.email}</Item.Header>
                  <Item.Meta className="invitation-meta">
                    <strong>Category:</strong>{membershipLabels[invitation.membership_category]} {' • '}
                    <strong>Created:</strong>{new Date(invitation.createdAt).toDateString()}
                  </Item.Meta>
                </Item.Content>
                <Item.Extra className="invitation-extra">
                  <Button className="remove-btn" onClick={() => removeInvitation(invitation)}>
                    Remove
                  </Button>
                </Item.Extra>
              </Item>
            ))
          ) : (
            <p className="no-invite">You do not have any pending invitations at the moment.</p>
          )}
        </Item.Group>
      </div>
    </OrganizationContent>
  );
};

export default Invitations;
